<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ i18n.$t('camera.title.' + (requiresVideo ? 'video' : (stillFrame ? 'still_frame' : 'image'))) }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header> <!-- TODO Can you manually focus with userMedia? -->

    <ion-content :fullscreen="false" :scroll-y="false">
      <ion-card v-if="(stream != null || nativeCameraPreviewRunning || cameraErrorState || captureState.includes('after_capture')) && captureState.length > 0" class="information-container">
        <ion-icon
          :icon="(cameraErrorState && !captureState.includes('after_capture')) ? alertCircleOutline : informationCircleOutline" 
          size="large"
          :color="(cameraErrorState && !captureState.includes('after_capture')) ? 'warning' : undefined">
        </ion-icon>
        <div v-if="!cameraErrorState || (captureState.includes('after_capture'))">{{ captureHint }}</div>
        <div v-else> <!-- Show camera error only while capturing -->
          <b>
            <h2>{{ i18n.$t('camera.unavailable.title') }}</h2>
            <p>{{ i18n.$t('camera.unavailable.hint_list') }}</p>
          </b>
          <p v-for="(hint, index) of captureDescriptions" :key="index">{{ index + 1 }}. {{ hint }}</p
        ></div>
      </ion-card>
      <div v-if="capturedImage != null" class="image-page">
        <div class="image-outer-container">
          <EditingCanvas :style="((capturedImage != null && enableCrop) ? undefined : 'display: none')" ref="editingOverlayCanvas" id="image-edit" :editingElement="capturedImageElement" :aspectRatio="aspectRatio || cameraOptions.aspectRatio" :preserveAspectRatio="aspectRatio != null" @interactionChange="isCanvasInteracting = $event" @updateAspectRatios="checkAspectRatios"></EditingCanvas>
          <img :src="capturedImage" ref="capturedImageElement">
        </div>
        <ion-item lines="none" class="editing-controls">
          <div>
            <div class="button-container">
              <ion-buttons>
                <ion-button fill="clear" color="danger" @click="clearCapturesAndRestart()">
                  <ion-icon slot="icon-only" :icon="close"></ion-icon>
                </ion-button>
              </ion-buttons>
              <div class="center-buttons">
                <ion-buttons v-if="!enforceCrop" class="crop-buttons">
                  <ion-button class="enable-crop" fill="solid" :color="enableCrop ? 'tertiary' : undefined" @click="enableCrop = !enableCrop">
                    <ion-icon slot="icon-only" :icon="cropOutline"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </div>
              <ion-buttons>
                <ion-button fill="clear" color="success" @click="returnImage()" :disabled="isCanvasInteracting">
                  <ion-icon slot="icon-only" :icon="checkmark"></ion-icon>
                </ion-button>
              </ion-buttons>
            </div>
          </div>
        </ion-item>
      </div>
      <div v-if="capturedVideo != null" class="video-page">
        <div class="video-outer-container">
          <EditingCanvas v-if="stillFrame" :style="((capturedVideoMetadata != null && enableCrop) ? undefined : 'display: none')" ref="editingOverlayCanvas" id="video-edit" :editingElement="capturedVideoPlayer" :aspectRatio="aspectRatio || cameraOptions.aspectRatio" :preserveAspectRatio="aspectRatio != null" @interactionChange="isCanvasInteracting = $event" @updateAspectRatios="checkAspectRatios"></EditingCanvas>
          <video ref="capturedVideoPlayer" @error="videoError" @loadeddata="setCapturedVideoMetadata" @durationchange="setCapturedVideoDuration" @timeupdate="setCapturedVideoTime" @play="isCapturedVideoPlaying = true" @pause="isCapturedVideoPlaying = false" muted playsinline>
            <source :src="capturedVideo" :type="overridenFileType || undefined">
          </video>
        </div>
        <ion-item lines="none" class="editing-controls">
          <div> <!-- FIXME If in chrome macos the metadata could not be fetched, the duration might not be divisable because of the wrong framerate, thus the last tick is not at the end of the range -->
            <ion-range 
              :style="processingVideo ? 'visibility: hidden;' : ''"
              :min="capturedVideoFrameTime"
              :disabled="capturedVideoMetadata == null || isCapturedVideoPlaying || isCanvasInteracting"
              :max="(capturedVideoMetadata != null) ? capturedVideoMetadata.duration : 0"
              :step="capturedVideoFrameTime"
              color="secondary"
              :value="currentCapturedVideoPosition"
              @ionChange="(isCapturedVideoPlaying) ? null : (currentCapturedVideoPosition = $event.target.value)"
              snaps> <!-- Update of the video position is only called while the video is not running. Otherwise it will re-call its own handler -->
              <!-- TODO Make a second range for trimming in this page and only show the correct one for the format -->
              <ion-label class="frame-indicator" slot="end">
                {{ currentCapturedFrameIndex }}
                /
                {{(capturedVideoMetadata != null) ? capturedVideoMetadata.FrameCount : 0}}</ion-label>
            </ion-range>
            <div class="button-container">
              <ion-buttons>
                <ion-button fill="clear" color="danger" @click="clearCapturesAndRestart()">
                  <ion-icon slot="icon-only" :icon="close"></ion-icon>
                </ion-button>
              </ion-buttons>
              <div class="center-buttons">
                <div v-if="stillFrame" class="fill-element"></div>
                <ion-buttons class="scrub-buttons">
                  <ion-button fill="solid" color="primary" shape="round" @click="decrementCapturedVideoFrame" :disabled="capturedVideoMetadata == null || isCapturedVideoPlaying || isCanvasInteracting">
                    <ion-icon slot="icon-only" :icon="playSkipBack"></ion-icon>
                  </ion-button>
                  <ion-button fill="solid" color="secondary" @click="toggleCapturedVideoPlayState" :disabled="capturedVideoMetadata == null || isCanvasInteracting">
                    <ion-icon slot="icon-only" :icon="isCapturedVideoPlaying ? pause : play"></ion-icon>
                  </ion-button>
                  <ion-button fill="solid" color="primary" shape="round" @click="incrementCapturedVideoFrame" :disabled="capturedVideoMetadata == null || isCapturedVideoPlaying || isCanvasInteracting">
                    <ion-icon slot="icon-only" :icon="playSkipForward"></ion-icon>
                  </ion-button>
                </ion-buttons>
                <ion-buttons v-if="stillFrame && !enforceCrop" class="crop-buttons">
                  <ion-button v-if="stillFrame" class="enable-crop" fill="solid" :color="enableCrop ? 'tertiary' : undefined" @click="enableCrop = !enableCrop">
                    <ion-icon slot="icon-only" :icon="cropOutline"></ion-icon>
                  </ion-button>
                </ion-buttons>
                <div v-else-if="stillFrame" class="fill-element"></div>
              </div>
              <ion-buttons>
                <ion-button fill="clear" color="success" @click="returnVideo()" :disabled="capturedVideoMetadata == null || isCapturedVideoPlaying || isCanvasInteracting">
                  <ion-icon slot="icon-only" :icon="checkmark"></ion-icon>
                </ion-button>
              </ion-buttons>
            </div>
          </div>
        </ion-item>
      </div>
      <div v-if="!hasMediaBeenCaptured" class="camera-page">
        <div class="camera-outer-container">
          <!-- Camera view -->
          <video ref="videoPlayer" id="camera-view" :srcObject="stream" @error="videoError" autoplay muted playsinline></video>
        </div>
        <ion-fab v-if="!hasMediaBeenCaptured" vertical="bottom" :horizontal="isLandscape ? 'end' : 'start'" slot="fixed">
          <input ref="mediaUpload" type="file"
          :accept="acceptFileTypeUpload"
          style="display: none;"
          @change="fileChosen">
          <ion-fab-button @click="mediaUpload.click()" :class="(cameraErrorState || onErrorHasRetried) ? 'attention' : ''"> <!-- On second try or on error highlight gallery as an option. -->
            <ion-icon :icon="image"></ion-icon>
          </ion-fab-button>
        </ion-fab>
        <ion-fab v-if="!hasMediaBeenCaptured" :vertical="isLandscape ? 'center' : 'bottom'" :horizontal="isLandscape ? 'end' : 'center'" slot="fixed"
        :style="(stream == null && nativeCameraPreviewRunning == false) ? 'display:none' : ''">
          <!-- In Safari Desktop the stream is created and the camera might need some time to turn on, while the recording is already possible. But capturing still starts from the first frame, so all good. -->
          <ion-fab-button @click="cameraTrigger()" 
            :style="currentlyRecording ? '--background: transparent;' : ''"
            :disabled="currentlyRecording && processingVideo">
            <CircularProgress
              class="capture-progress"
              :style="currentlyRecording ? '--color: var(--ion-color-secondary);' : ''"
              :progress="captureProgress"
              :showProgress="currentlyRecording">
              <ion-icon :icon="currentlyRecording ? (processingVideo ? hourglassOutline : stop) : ((requiresVideo || stillFrame) ? videocam : camera)"></ion-icon>
            </CircularProgress>
          </ion-fab-button>
        </ion-fab>
        <ion-fab v-if="!hasMediaBeenCaptured" :vertical="isLandscape ? 'top' : 'bottom'" horizontal="end" slot="fixed"
        :style="(stream != null || nativeCameraPreviewRunning == true) && flashSupported ? '' : 'display:none'"
        :class="(captureState === 'flash_off') ? 'attention' : ''">
          <ion-fab-button @click="toggleFlash()">
            <ion-icon :icon="flashActive ? flash : flashOff"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { isPlatform, IonFab, IonFabButton, IonIcon, IonPage, IonTitle, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonCard, IonItem, IonRange, IonLabel, modalController } from '@ionic/vue';

import { camera, videocam, image, flash, flashOff, stop, hourglassOutline, informationCircleOutline, alertCircleOutline, close, checkmark, play, pause, playSkipBack, playSkipForward, cropOutline } from 'ionicons/icons';

import { defineComponent, ref, computed, onMounted, watch } from 'vue';

import CircularProgress from '@/components/CircularProgress.vue';

import EditingCanvas from '@/components/EditingCanvas.vue';

import { useI18n } from "@/utils/i18n";

import { localError, localErrorToast } from '@/utils/error';

import { getVideoMetadata, getFrameInfoFromMetadata, base64ToBlob, splitDataURL } from '@/utils/media';

const CameraModal = defineComponent({
  name: 'Camera',
  components: { IonFab, IonFabButton, IonIcon, IonPage, IonTitle, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonCard, IonItem, IonRange, IonLabel, CircularProgress, EditingCanvas },
  props: {
    'requiresVideo': Boolean,
    'stillFrame': Boolean,
    'flashSuggested': Boolean,
    'guideInstance': Object,
    'captureType': String,
    'width': { //TODO Check if width, height and cameraDirection can be set from here! TEST on iOS and Android
      type: Number,
      default: 1920
    },
    'height': {
      type: Number,
      default: 1080
    },
    'aspectRatio': Number,
    'cameraDirection': {
      type: String,
      default: 'back'
    },
    'selectedFile': Object
  },
  setup(props) {
    const i18n = useI18n();

    const videoPlayer = ref(null);
    const capturedVideoPlayer = ref(null);
    const capturedImageElement = ref(null);
    const editingOverlayCanvas = ref(null);
    const mediaUpload = ref(null);
    const iosCameraCanvas = ref(document.createElement('canvas'));

    const hasBeenDismissed = ref(false);

    const cameraErrorState = ref(false);
    const onErrorHasRetried = ref(false);
    const enableCrop = ref(false);
    const enforceCrop = ref(false);
    const isCanvasInteracting = ref(false);

    const preSelectedFile = ref(props.selectedFile);

    //Containers and codecs specified in this order will be tried with a fallback being the default for the device
    const acceptedVideoRecordingCodecs = ['video/mp4', 'video/webm;codecs=h264'];
    const MIN_BITRATE = 5000000;
    const BITRATE_FACTOR = 12;

    //Accomodate for limitations of mobile devices //TODO Add notification to download app for higher quality video! After Android is also available natively
    const MAX_MEDIARECORDER_VIDEOSIZE = {
      width: 800,
      height: 480
    }

    const capturedVideoDuration = ref(0);
    const capturedVideoFrameInfo = ref(null);
    const capturedVideoMetadata = computed(() => {
      if (capturedVideoFrameInfo.value == null) {
        return null;
      }

      let duration = capturedVideoDuration.value;
      if (duration == Infinity || duration == null) {
        duration = 0;
      }

      let metadata = {
        FrameRate: (capturedVideoFrameInfo.value.FrameRate != null) ? capturedVideoFrameInfo.value.FrameRate : 25,
        duration
      }

      if (capturedVideoFrameInfo.value.FrameCount > 0) {
        metadata.FrameCount = capturedVideoFrameInfo.value.FrameCount;
      } else {
        metadata.FrameCount = Math.round(duration * metadata.FrameRate);
      }

      return metadata;
    });
    const capturedVideoFrameTime = computed(() => {
      if (capturedVideoMetadata.value != null && capturedVideoMetadata.value.FrameRate != null) {
        return 1 / capturedVideoMetadata.value.FrameRate;
      } else {
        return 0.04;
      }
    });
    const currentCapturedFrameIndex = computed(() => {
      if (capturedVideoMetadata.value != null && capturedVideoMetadata.value.FrameRate != null) {
        return Math.round(currentCapturedVideoPosition.value * capturedVideoMetadata.value.FrameRate);
      } else {
        return 0;
      }
    });

    const currentCapturedVideoTime = ref(0);
    const isCapturedVideoPlaying = ref(false);

    const setCapturedVideoTime = function() {
      //For protection reasons browsers may not update the currentTime when paused, so only use this to update while playing
      if (isCapturedVideoPlaying.value) {
        currentCapturedVideoTime.value = capturedVideoPlayer.value.currentTime;
      }
    }

    //TODO When doing a double range do not allow playing outside of trimmed area, indicate play position in some other way (pin on the bar or smth). When moving left or right handle, stop video and seek there to indicate current searching frame.
    const currentCapturedVideoPosition = computed({
      get: () => {
        return currentCapturedVideoTime.value;
      },
      set: (newPosition) => {
        if (capturedVideoPlayer.value != null) {
          if (newPosition > capturedVideoPlayer.value.duration) newPosition = capturedVideoPlayer.value.duration; //Limit max to video length
          if (currentCapturedVideoTime.value != newPosition) { //Only update if it changed
            capturedVideoPlayer.value.currentTime = currentCapturedVideoTime.value = newPosition;
          }
        }
      } 
    });

    const setCapturedVideoDuration = function() {
      capturedVideoDuration.value = capturedVideoPlayer.value.duration;
      if (capturedVideoDuration.value == Infinity) {
        capturedVideoPlayer.value.currentTime = 1e101; //Fix for webkit browsers like Chrome on macOS. Set the time to a really high value to force load the video, then we get the correct duration
      } else {
        capturedVideoPlayer.value.currentTime = capturedVideoFrameTime.value;
      }
    }

    const setCapturedVideoMetadata = function() {
      //Set default values
      currentCapturedVideoTime.value = 0;
      isCapturedVideoPlaying.value = false;
      capturedVideoPlayer.value.pause();

      //Create a File Object out of the blob from the file and then use utils to get the metadata for it
      fetch(capturedVideo.value)
      .then(videoResult => videoResult.blob())
      .then(blobFile => new File([blobFile], 'capture'))
      .then(videoFile => getVideoMetadata(videoFile))
      .then(metadata => {
        let frameInfo = getFrameInfoFromMetadata(metadata);
        if (frameInfo == null) {
          frameInfo = {
            FrameRate: 25
          }
        }
        capturedVideoFrameInfo.value = frameInfo;
      })
      .catch((error) => {
        console.error('Could not load metadata for video', error);
        capturedVideoFrameInfo.value = {
            FrameRate: 25
        };
      })
      .finally(() => {
        currentCapturedVideoTime.value = capturedVideoFrameTime.value; //Set to first frame initially
        processingVideo.value = false;
      });
    }

    //Pause video when an interaction occurs
    watch(isCanvasInteracting, (isInteracting) => {
      if (isInteracting && capturedVideoPlayer.value != null) capturedVideoPlayer.value.pause();
    })

    const toggleCapturedVideoPlayState = function(){
      if (capturedVideoPlayer.value != null) {
        if (isCapturedVideoPlaying.value) {
          capturedVideoPlayer.value.pause();
        } else {
          capturedVideoPlayer.value.play();
        }
      }
    }

    const incrementCapturedVideoFrame = function() {
      currentCapturedVideoPosition.value += capturedVideoFrameTime.value;
    }

    const decrementCapturedVideoFrame = function() {
      let newVideoPosition = currentCapturedVideoPosition.value - capturedVideoFrameTime.value;
      if (newVideoPosition > (capturedVideoFrameTime.value / 2)) { //Allow substraction only to the first frame, not below
        currentCapturedVideoPosition.value = newVideoPosition;
      }
    }

    const nativeCameraPreviewRunning = ref(false);
    const stream = ref(null); //Stores the camera stream. null if uninitialized

    const flashSupported = ref(false);
    const flashActive = ref(false);

    const RECORDING_TIMESLICE = 100; //Time in milliseconds each blob contains in a sliced recording
    const MAX_VIDEO_TIME = 60 * 1000; //Allow maximum 60 seconds of video
    const STILL_FRAME_TIME = 5000; //Time to hold still for capturing still frame

    const capturedImage = ref(null);
    const capturedVideo = ref(null);

    const overridenFileType = ref(null);

    const hasMediaBeenCaptured = computed(() => capturedImage.value != null || capturedVideo.value != null);
    const processingVideo = ref(false);

    const recorder = ref(null);
    const captureProgress = ref(-1);
    const nativeRecordingPromiseResolve = ref(null);
    const nativeImageCapturePromise = ref(null);

    const landscapeQuery = window.matchMedia("(orientation: landscape)");

    const isLandscape = ref(landscapeQuery.matches);

    landscapeQuery.addEventListener('change', (query) => {
      isLandscape.value = query.matches;
    });

    watch([capturedVideo, capturedVideoPlayer], ([newVideo, player]) => {
      if (newVideo != null && player != null) {
        player.load();
      }
    });

    watch([stream, videoPlayer], ([newStream, player]) => {
      if (newStream != null && player != null) {
        player.load();
      }
    });

    //Reset capture related states
    watch(hasMediaBeenCaptured, (captured) => {
      isCanvasInteracting.value = false;

      if (!captured) {
        //Only set when leaving capture preview again
        enableCrop.value = false;
        enforceCrop.value = false;
      }
    });

    //Check if aspect ratio matches desired one and enforce if not - Called on each start of the editing canvas, sets the correct state
    const checkAspectRatios = function(aspectRatios){
      if (props.aspectRatio != null && Math.abs(aspectRatios.canvas - aspectRatios.element) > 0.001) {
        enableCrop.value = true;
        enforceCrop.value = true;
        return;
      }
      enableCrop.value = false;
      enforceCrop.value = false;
    }

    const currentlyRecording = computed(() => {
      return recorder.value != null || (captureProgress.value >= 0 && captureProgress.value < 100);
    });

    const captureState = computed(() => {
      if (currentlyRecording.value) {
        return 'during_capture';
      } else if (hasMediaBeenCaptured.value) {
        if (capturedVideo.value != null) {
          if (props.stillFrame) {
            return 'after_capture_still';
          } else {
            return ''; //TODO Add proper return, once trimming works //return 'after_capture_video'; 
          }
        } else {
          return 'after_capture_image';
        }
      } else if (props.flashSuggested && flashSupported.value && !flashActive.value) {
        return 'flash_off';
      } else {
        return 'before_capture';
      }
    });

    const captureDescriptions = computed(() => {
      let captureType = props.captureType || 'default';
      
      let hints = [];
      let captureHintObject = i18n.$t(`camera.hint.${captureType}`);
      if (captureHintObject == null) //Use default as fallback if a captureType does not exist
        captureHintObject = i18n.$t(`camera.hint.default`);
      //Exclude hints of the editor, handled separately. Also don't include flash hints if flash is not suggested.
      for (let [state, hint] of Object.entries(captureHintObject)) {
        if (!state.includes('after_capture') && !state.includes('descriptor') && !(!props.flashSuggested && state === 'flash_off')) hints.push(hint);
      }
      return hints;
    });

    const captureHint = computed(() => {
      let captureType = props.captureType || 'default';
      let hint = i18n.$t(`camera.hint.${captureType}.${captureState.value}`);
      if (hint != null && hint.length > 0) 
        return hint;
      else //Use default as fallback if a hint is not provided for that state
        return i18n.$t(`camera.hint.default.${captureState.value}`);
    });

    const acceptFileTypeUpload = computed(() => {
      if (props.requiresVideo) {
        return 'video/*';
      } else if (props.stillFrame) {
        return 'video/*,image/*'
      } else {
        return 'image/*';
      }
    });

    //Attach close listener to this modal through the modal controller when it opens
    modalController.getTop()
      .then((modal) => modal.onWillDismiss())
      .then((resultURL) => {
        //Clear the captures, when they are not the ones given back from the modal, to save on resources
        if (capturedVideo.value != null && resultURL.data != null && capturedVideo.value != resultURL.data.url) URL.revokeObjectURL(capturedVideo.value);
        capturedVideo.value = null;

        if (capturedImage.value != null && resultURL.data != null && capturedImage.value != resultURL.data.url) URL.revokeObjectURL(capturedImage.value);
        capturedImage.value = null;

        overridenFileType.value = null;
        closeCameraResources();
      });

    const cameraUnavailable = function(permissionMissing = false){
      console.error('Camera unavailable. Permission missing?: ' + permissionMissing);
      cameraErrorState.value = true;
      nativeCameraPreviewRunning.value = false;
      stream.value = null;
      flashSupported.value = false;
    }

    const retryRecording = function() {
      localErrorToast(i18n, i18n.$t('camera.error_retry'));
      closeCameraResources();
      clearCaptures();
      //Only retry once during each session
      if (!onErrorHasRetried.value) {
        onErrorHasRetried.value = true;
        setTimeout(() => restartCamera(), 500); //Delay restart a bit to be ready!
      } else {
        cameraErrorState.value = true;
      }
    }

    const videoError = function(error) {
      console.error("Could not load video: ", error);
      retryRecording();
    } //FIXME After going to sleep captureStream is no longer valid! Only sometimes!

    const closeModal = function(returnValue, returnType = undefined){
      hasBeenDismissed.value = true;
      let returnObject = null;
      if (returnValue != null) {
        returnObject = {
          url: returnValue,
          type: returnType
        }
      }
      return modalController.dismiss(returnObject);
    }

    const closeCameraResources = function(){
      if (currentlyRecording.value && recorder.value != null && recorder.value.state == "recording") {
        recorder.value.stop();
      }
      if (nativeCameraPreviewRunning.value) {
        window.plugin.CanvasCamera.stop(null, () => {
          nativeCameraPreviewRunning.value = false;
        });
      } 
      nativeRecordingPromiseResolve.value = null;
      nativeImageCapturePromise.value = null;
      
      if (stream.value != null) {
        stream.value.getTracks().forEach((track) => {
          track.stop();
        });
        stream.value = null;
      }
    }

    const clearCaptures = function() {
      if (capturedVideo.value != null) URL.revokeObjectURL(capturedVideo.value);
      capturedVideo.value = null;

      if (capturedImage.value != null) URL.revokeObjectURL(capturedImage.value);
      capturedImage.value = null;

      overridenFileType.value = null;

      capturedVideoDuration.value = 0;
      capturedVideoFrameInfo.value = null;
      currentCapturedVideoTime.value = 0;
      isCapturedVideoPlaying.value = false;
    }

    const restartCamera = function() {
      //If a preset file has been given, we don't invoke the capture screen
      if (preSelectedFile.value != null) {
        //We don't capture, return
        closeModal();
      } else {
        //New capture starts, open the camera again
        initializeCamera();
      }
    }

    const clearCapturesAndRestart = function() {
      clearCaptures();
      restartCamera();
    }

    function initializeUserMediaCamera(cameraOptions){
      // Check if API is available
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)){
        console.error('UserMedia API is not available. Camera can not be used');
        cameraUnavailable();
        return;
      }

      let facingMode = 'environment';
      if (cameraOptions.camera == 'front') {
        facingMode = 'user';
      }

      flashActive.value = false;

      let constraints = {
        video: {
          facingMode,
          width: (props.requiresVideo || props.stillFrame) ? Math.min(cameraOptions.width, MAX_MEDIARECORDER_VIDEOSIZE.width) : cameraOptions.width,
          height: (props.requiresVideo || props.stillFrame) ? Math.min(cameraOptions.height, MAX_MEDIARECORDER_VIDEOSIZE.height) : cameraOptions.height,
          //Always allow full recording frame and let user crop later //aspectRatio: cameraOptions.aspectRatio
        },
        advanced: [{torch: false}] //Turn off flash by default
      };

      navigator.mediaDevices.getUserMedia(constraints)
      .then((newStream) => {
        stream.value = newStream;

        let track = stream.value.getTracks()[0];

        // Workaround for slow initialization of camera
        window.setTimeout(() => {
            if (!hasBeenDismissed.value && "torch" in track.getCapabilities()) {
              flashSupported.value = true;
            }
        }, 1000);

        if (hasBeenDismissed.value) closeCameraResources(); //Catch the case where the modal is closed before the camera has been initialized
      })
      .catch((error) => {
        console.error(error);
        cameraUnavailable(true);
      });
    }

    function setFile(file, errorCallback) {
      if (file != null) {
        if (file.type.includes('image')) {
          capturedImage.value = URL.createObjectURL(file);
        } else if (file.type.includes('video')) {
          if (videoPlayer.value.canPlayType(file.type) !== '') {
            processingVideo.value = true;
            capturedVideo.value = URL.createObjectURL(file);
          } else {
            let callbackComplete = Promise.resolve();
            if (errorCallback) callbackComplete = callbackComplete.then(() => errorCallback());
            return callbackComplete.then(() => localError(i18n, i18n.$t('camera.file-type-not-supported'))).then(() => false);
          }
        } else {
          let callbackComplete = Promise.resolve();
          if (errorCallback) callbackComplete = callbackComplete.then(() => errorCallback());
          return callbackComplete.then(() => localError(i18n, i18n.$t('camera.invalid-file-type'))).then(() => false);
        }
      }
      return Promise.resolve(true);
    }

    function fileChosen(){
      //Only use if upload was successful, i.e. not cancelled
      if (mediaUpload.value.files.length > 0) {
        let file = mediaUpload.value.files[0];

        setFile(file).then((success) => {
          if (success) closeCameraResources();
        });
      }
    }

    const captureNativeVideo = function(lengthInMS) {
      if (nativeCameraPreviewRunning.value) { 
        //Reset processing state just in case it didn't reset after finishing
        processingVideo.value = false;
        nativeRecordingPromiseResolve.value = null;

        let progressUpdateInterval = null;

        let nativeRecordingPromise = new Promise((resolve, reject) => {
          window.plugin.CanvasCamera.startVideoRecording((error) => {
            reject(error);
          },
          (result) => {
            if (result != null) {
              let videoURL = result.videoURL;
              if (videoURL == null) {
                reject('Native videoURL not provided!');
                return;
              }
              //Create a function to resolve from the outside
              nativeRecordingPromiseResolve.value = () => resolve(videoURL);

              captureProgress.value = 0;
              progressUpdateInterval = setInterval(() => {
                if (captureProgress.value < 100) captureProgress.value += 1;
                else {
                  clearInterval(progressUpdateInterval);
                  resolve(videoURL);
                }
              }, lengthInMS / 100); //In percent progress, so update every 100th of the total length
            }
          });
        });

        return new Promise((resolve, reject) => {
          nativeRecordingPromise.then((videoURL) => {
            window.plugin.CanvasCamera.stopVideoRecording((error) => {
              throw error;
            },
            () => {
              processingVideo.value = true;
              resolve(videoURL);
            });
          })
          .catch((error) => {
            processingVideo.value = false;
            reject(error);
          })
          .finally(() => {
            clearInterval(progressUpdateInterval); 
            captureProgress.value = -1;
            nativeRecordingPromiseResolve.value = null;
          })
        });
      }
    }

    const captureVideo = function(lengthInMS) {
      if (stream.value != null) {
        //Reset processing state just in case it didn't reset after finishing
        processingVideo.value = false;

        let videoBitsPerSecond = MIN_BITRATE;

        for (let track of stream.value.getVideoTracks()) {
          let settings = track.getSettings();
          //Always use the higher number for the bitrate
          videoBitsPerSecond = Math.max(videoBitsPerSecond, (settings.width * settings.height * BITRATE_FACTOR));
        }

        //If a unsupported type was requested, then don't use it and use the default one - no specific one requested
        let recordingOptions = {
          videoBitsPerSecond
        };

        for (let codec of acceptedVideoRecordingCodecs) {
          if (MediaRecorder.isTypeSupported(codec)) {
            recordingOptions['mimeType'] = codec;
            break;
          }
        }

        recorder.value = new MediaRecorder(stream.value, recordingOptions);

        let data = [];
        
        recorder.value.ondataavailable = event => data.push(event.data); //In default behaviour always returns full recording as a single blob

        recorder.value.start();

        //Using timeslice fixes some recordings being empty if no data is ready at the beginning yet, those empty slices get discarded.
        setTimeout(() => {
          try {
            if (recorder.value) recorder.value.requestData(); //Just creating one timeslice instead of slicing the whole blob to reduce stutter at the borders of the chunks.
          } catch (error) {
            console.error(error);
          }
        }, RECORDING_TIMESLICE); 

        let stopped = new Promise((resolve, reject) => {
          recorder.value.onstop = () => {
            let type = recordingOptions.mimeType;
            //Check each chunk for the type
            for (let chunk of data) {
              if (chunk.type.length) {
                type = chunk.type;
              }
            }
              
            let result = new Blob(data, { type });
            if (result.size > 0) { //Data has been successfully recorded
              resolve(result);
            } else {
              reject('MediaRecorder Error: Empty recording!');
            }
            
          }
          recorder.value.addEventListener('error', error => reject(error));
        });

        let progressUpdateInterval = null;
        let stopTimeout = null;

        recorder.value.onstart = () => {
          captureProgress.value = 0;
          progressUpdateInterval = setInterval(() => {
            if (captureProgress.value < 100) captureProgress.value += 1;
            else {
              clearInterval(progressUpdateInterval);
              if (recorder.value != null && recorder.value.state == "recording"){
                recorder.value.stop();
              }
            }
          }, lengthInMS / 100); //In percent progress, so update every 100th of the total length
        };

        return new Promise((resolve, reject) => {
          stopped.then((result) => {
            processingVideo.value = true;
            resolve(result);
          })
          .catch((error) => {
            processingVideo.value = false;
            reject(error)
          })
          .finally(() => {
            clearTimeout(stopTimeout);
            clearInterval(progressUpdateInterval); 
            captureProgress.value = -1;
            recorder.value = null;
          })
        });
      }
    }

    const captureImage = function(videoInstance, completionFunction){
      let captureCanvas = document.createElement('canvas');

      captureCanvas.width = videoInstance.videoWidth;
      captureCanvas.height = videoInstance.videoHeight;
      captureCanvas.getContext('2d').drawImage(videoInstance, 0, 0);

      if (completionFunction != null) {
        completionFunction();
      }

      return new Promise((resolve) => {
        captureCanvas.toBlob((blob) => resolve(blob), 'image/png');
      });
    }

    const cropImage = function(imageBlobURL, cropRectangle) {
      let cropCanvas = document.createElement('canvas');
      let image = new Image();
      image.crossOrigin = 'Anonymous';

      let promise = new Promise((resolve, reject) => {
        if (!('x' in cropRectangle && 'y' in cropRectangle && 'width' in cropRectangle && 'height' in cropRectangle)) reject('Missing crop attribute!');
        image.addEventListener('load', () => {
          cropCanvas.width = image.width * cropRectangle.width;
          cropCanvas.height = image.height * cropRectangle.height;
          cropCanvas.getContext('2d').drawImage(image, image.width * cropRectangle.x, image.height * cropRectangle.y, cropCanvas.width, cropCanvas.height, 0, 0, cropCanvas.width, cropCanvas.height);

          cropCanvas.toBlob((blob) => resolve(blob), 'image/png');
        });
        image.addEventListener('error', (error) => reject(error));
      });

      image.src = imageBlobURL;

      return promise;
    }

    function cameraTrigger(){ //FIXME Prevent that you can press record, when stream is not ready yet - Otherwise it runs into an error
      if (currentlyRecording.value) {
        if (recorder.value != null && recorder.value.state == "recording") recorder.value.stop();
        if (nativeRecordingPromiseResolve.value != null) nativeRecordingPromiseResolve.value();
      } else {
        let videoPromise = null;
        let nativeVideoPromise = null;
        if (props.requiresVideo) {
          if (nativeCameraPreviewRunning.value) nativeVideoPromise = captureNativeVideo(MAX_VIDEO_TIME);
          else videoPromise = captureVideo(MAX_VIDEO_TIME);
        } else if (props.stillFrame) {
          if (nativeCameraPreviewRunning.value) nativeVideoPromise = captureNativeVideo(STILL_FRAME_TIME);
          else videoPromise = captureVideo(STILL_FRAME_TIME);
        } else {
          let imagePromise = null;
          //Native recording just tells the code to capture a full size image on the next possible occasion and return it through the usual capture handler that fulfills this promise from outside
          if (nativeCameraPreviewRunning.value) imagePromise = new Promise((resolve, reject) => {
            let frameWaitTimeout;
            nativeImageCapturePromise.value = {
              resolve: (data) => {
                clearTimeout(frameWaitTimeout);
                resolve(data);
              },
              reject: (error) => reject(error)
            }
            window.plugin.CanvasCamera.requestSingleFullsize((error) => {
              reject(error);
            });
            //Add a timeout for waiting for a full size image to be returned that rejects this promise
            frameWaitTimeout = setTimeout(() => {
              reject('No frame received in time');
            }, 5000);
          });
          else imagePromise = captureImage(videoPlayer.value, closeCameraResources);
          
          imagePromise.then((imageBlob) => {
            capturedImage.value = URL.createObjectURL(imageBlob);
            closeCameraResources();
          })
          .catch((error) => {
            console.error(error);
            retryRecording();
          });
        }

        if (videoPromise != null) {
          videoPromise.then((video) => {
            let recordedBlob;
            //TODO Check Chrome on Windows and mobile browsers too
            //Chrome returns matroska container that needs to be converted
            if (video instanceof Blob && !video.type.includes('matroska')) { //If we get a blob from the recorder use it
              recordedBlob = video;
            } else { //Otherwise create a webm video out of it
              recordedBlob = new Blob([video], { type: "video/webm" });
            }
            capturedVideo.value = URL.createObjectURL(recordedBlob); //iOS PWA might crash on large files while scrubbing or playing. Known issue.
            
            closeCameraResources();
          })
          .catch((error) => {
            console.error(error);
            retryRecording();
          });
        }

        if (nativeVideoPromise != null) {
          nativeVideoPromise.then((videoURL) => {
            capturedVideo.value = videoURL;
            
            overridenFileType.value = 'video/mp4'; //Defined to be mp4 in plugin
            
            closeCameraResources();
          })
          .catch((error) => {
            console.error(error);
            retryRecording();
          });
        }
      }
    }

    function toggleFlash(){
      if (nativeCameraPreviewRunning.value) {
        flashActive.value = !flashActive.value;
        window.plugin.CanvasCamera.flashMode(flashActive.value, (error) => {
          console.error('Flash state could not be changed', error);
          flashSupported.value = null;
        });
      } else if (stream.value != null){
        let track = stream.value.getTracks()[0];

        let newState = !(track.getSettings().torch);

        track.applyConstraints({
          advanced: [{torch: newState}]
        })
        .then(() => {
          flashActive.value = newState;
        })
        .catch((error) => {
          console.error('Flash state could not be changed', error);
        });
      }
    }

    const cameraOptions = computed(() => {
      return {
        width: props.width || 1920,
        height: props.height || 1080,
        aspectRatio: (props.width && props.height) ? (props.width/props.height) : 16/9,
        camera: props.cameraDirection || 'back'
      }
    });


    const initializeNativeiOSCamera = function(cameraOptions) {
      window.plugin.CanvasCamera.initialize(null, iosCameraCanvas.value);

      let options = {
          cameraFacing: cameraOptions.camera,
          width: cameraOptions.width,
          height: cameraOptions.height,
          canvas: {
            width: cameraOptions.width,
            height: cameraOptions.height
          },
          capture: {
            width: cameraOptions.width,
            height: cameraOptions.height
          },
          fps: 30,
          use: 'data',
          flashMode: false,
          hasThumbnail: true,
          thumbnailRatio: 0.4,
          disableFullsize: true,
          drawOffscreen: true
      };

      flashActive.value = false;

      let cameraStartPromise = new Promise((resolve, reject) => window.plugin.CanvasCamera.start(options, reject, (data) => {
        resolve();
        //If full size data is available, send it to the waiting promise
        try {
          if (data != null && data.output != null && data.output.images != null && data.output.images.fullsize != null && data.output.images.fullsize.data != null) {
            let components = splitDataURL(data.output.images.fullsize.data);
            if (nativeImageCapturePromise.value != null) nativeImageCapturePromise.value.resolve(base64ToBlob(components.data, components.mime));
          }
        } catch (error) {
          if (nativeImageCapturePromise.value != null) nativeImageCapturePromise.value.reject(error);
        }
        
      }));
      
      cameraStartPromise
      .then(() => {
        nativeCameraPreviewRunning.value = true;        

        stream.value = iosCameraCanvas.value.captureStream();
        flashSupported.value = true;
        if (hasBeenDismissed.value) closeCameraResources(); //Catch the case where the modal is closed before the camera has been initialized
      }).catch(() => {
        cameraUnavailable(true);
      }) 
    }

    const initializeCamera = function() {
      cameraErrorState.value = false;
      if (preSelectedFile.value != null) {
        cameraErrorState.value = true; //Use gallery button as fallback
      } else {
        if (isPlatform('hybrid') && isPlatform('ios')) {
          initializeNativeiOSCamera(cameraOptions.value);
        } else {
          initializeUserMediaCamera(cameraOptions.value);
        }
      }
    }

    const returnVideo = function(){
      if (props.stillFrame) {
        let finalImageBlobURL;
        let oldImage;
        let imagePromise = captureImage(capturedVideoPlayer.value).then((imageBlob) => finalImageBlobURL = URL.createObjectURL(imageBlob));

        if (enableCrop.value && editingOverlayCanvas.value != null) {
          imagePromise = imagePromise.then(() => {
            oldImage = finalImageBlobURL;
          })
          .then(() => cropImage(oldImage, editingOverlayCanvas.value.getSelectionRectangle()))
          .then((imageBlob) => {
            finalImageBlobURL = URL.createObjectURL(imageBlob);
            URL.revokeObjectURL(oldImage);
          })
          .catch((error) => console.error('Could not crop image!', error));
        }
        imagePromise.finally(() => {
          if (finalImageBlobURL != null)
            closeModal(finalImageBlobURL);
          else
            localErrorToast(i18n, i18n.$t('camera.error_retry_finalize'));
        });

        return;
      }
      //TODO Trim video here if it is requested or return untrimmed one if no trimming is necessary
      if (capturedVideo.value != null)
        closeModal(capturedVideo.value, overridenFileType.value); //Video is by design always requested as mp4 for better cross-compatibility
      else
        localErrorToast(i18n, i18n.$t('camera.error_retry_finalize'));
      //TODO Maybe the video can be converted on the client side to mp4 or smth like that if it is not already
    }

    const returnImage = function(){
      if (enableCrop.value && editingOverlayCanvas.value != null) {
        let oldImage = capturedImage.value;
        cropImage(oldImage, editingOverlayCanvas.value.getSelectionRectangle()).then((imageBlob) => {
          capturedImage.value = URL.createObjectURL(imageBlob);
          URL.revokeObjectURL(oldImage);
        })
        .catch((error) => console.error('Could not crop image!', error))
        .finally(() => {
          if (capturedImage.value != null)
            closeModal(capturedImage.value);
          else
            localErrorToast(i18n, i18n.$t('camera.error_retry_finalize'));
        });
      } else {
        if (capturedImage.value != null)
          closeModal(capturedImage.value);
        else
          localErrorToast(i18n, i18n.$t('camera.error_retry_finalize'));
      }
    }

    onMounted(() => {
      if (preSelectedFile.value != null) {
        setFile(preSelectedFile.value, () => {
          preSelectedFile.value = null;
          initializeCamera();
        });
      } else {
        initializeCamera();
      }
    });

    return {
      i18n,
      isLandscape,
      closeModal,
      clearCaptures,
      clearCapturesAndRestart,
      videoError,
      videoPlayer,
      capturedVideoPlayer,
      capturedImageElement,
      editingOverlayCanvas,
      isCanvasInteracting,
      currentCapturedVideoPosition,
      currentCapturedFrameIndex,
      capturedVideoMetadata,
      capturedVideoFrameTime,
      currentCapturedVideoTime,
      isCapturedVideoPlaying,
      cameraErrorState,
      onErrorHasRetried,
      cameraOptions,
      enableCrop,
      enforceCrop,
      setCapturedVideoTime,
      setCapturedVideoDuration,
      setCapturedVideoMetadata,
      toggleCapturedVideoPlayState,
      incrementCapturedVideoFrame,
      decrementCapturedVideoFrame,
      mediaUpload,
      acceptFileTypeUpload,
      nativeCameraPreviewRunning,
      currentlyRecording,
      capturedImage,
      capturedVideo,
      overridenFileType,
      hasMediaBeenCaptured,
      checkAspectRatios,
      processingVideo,
      captureProgress,
      captureState,
      captureDescriptions,
      captureHint,
      stream,
      fileChosen,
      cameraTrigger,
      toggleFlash,
      returnVideo,
      returnImage,
      camera,
      videocam,
      flashSupported,
      flashActive,
      image,
      flash,
      flashOff,
      stop,
      hourglassOutline,
      informationCircleOutline,
      alertCircleOutline,
      close,
      checkmark,
      play,
      pause,
      playSkipBack,
      playSkipForward,
      cropOutline
    };
  }
});

export async function openCameraModal(component, properties){
  if (component != null) {
    const modal = await modalController
      .create({
        component,
        cssClass: 'camera-modal',
        componentProps: properties,
      })
    modal.present();
    return modal.onDidDismiss();
  }
}

export default CameraModal;
</script>

<style>
.camera-modal {
  --width: 100%;
  --height: 100%;
}
</style>

<style scoped>
ion-fab-button {
  --background: var(--ion-background-color, #fff);
  --background-activated: var(--ion-background-color, #fff);
  --background-focused: var(--ion-background-color, #fff);
  --background-hover: var(--ion-background-color, #fff);
  --border-color: rgba(var(--ion-color-primary-rgb, 255,255,255), 1);
  --color: rgba(var(--ion-color-primary-rgb, 255,255,255), 1);
  --border-width: 2px;
  --border-style: solid;
}
.camera-page, .image-page, .video-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.camera-outer-container, .video-outer-container, .image-outer-container {
  width: 100%;
  position: relative; /*Position has to be relative for aspect ratio to work*/
  overflow: hidden;
  flex-grow: 10;
  flex-shrink: 1;
  /* Fix for webkit sometimes ignoring its z-index on initial placement */
  transform: translateZ(0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.camera-outer-container > video {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: translateZ(0);
}

ion-fab {
  transform: translateZ(1px);
}

.video-outer-container, .image-outer-container {
  display: flex;
  
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.image-outer-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.video-outer-container > video {
  max-width: 100%;
  max-height: 100%;
}

.editing-controls {
  width: 100%;
  --padding-start: 10px;
  --inner-padding-end: 10px;
  flex-shrink: 0;
}

.editing-controls > div {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.editing-controls .button-container {
  margin-top: 5px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.editing-controls .button-container .center-buttons .scrub-buttons > * {
  margin: 0px auto;
}

/*Create a box with aspect-ratio of 1:1*/
/*.camera-outer-container:before {
	content: '';
	float: left;
	padding-bottom: 100%;
}*/

#camera-view {
  width: 100%;
  height: 100%;
}

.capture-progress {
  font-size: 1.75em;
}

.information-container {
  left: 0px;
  right: 0px;
  position: absolute;
  z-index: 1001;
  --background: rgba(var(--ion-card-background-rgb, 255,255,255), 0.9);
  --color: var(--ion-text-color, black);
  margin-top: 10px;

  display: flex;
  flex-flow: row;
  align-items: center;
  padding-right: 10px;
}

.information-container > * {
  opacity: 1!important;
  padding: 10px 0px 10px 10px;
}

.information-container ion-icon {
  min-width: 24px;
  max-width: 24px;
}

.frame-indicator {
  text-align: center;
  min-width: 50px;
}

@keyframes attention-scaling {
    0% {
        transform: scale(1.0) translateY(0px);
    }
    50% {
        transform: scale(1.15) translateY(-5px);
    }
    100% {
        transform: scale(1.0) translateY(0px);
    }
}

.attention {
  animation: attention-scaling 0.5s ease-in-out 0s infinite;
}

.center-buttons {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.crop-buttons, .fill-element {
  flex: 1;
}

.scrub-buttons {
  flex: 1;
  min-width: 150px;
  max-width: 200px;
}

.enable-crop {
  margin: 0px;
}

.crop-buttons {
  justify-content: flex-end;
  margin: 0px 3vw;
}

.crop-buttons.ios {
  margin: 0px 4vw;
}

#image-edit, #video-edit {
  z-index: 1000;
}
</style>
